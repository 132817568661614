// VARIABLES

$theme-colors: (
  "background": #F8F6F6,
  "black": #142935,
  "white": #ffffff,
  "grey": #DDDDDD,
  "light-grey": #F8F6F6,
  "pink": #E0C7C2,
  "primary": #2F4491,
  "primary-contrast": #FF6B38,
  "border": #C9C9C9,
  "darkborder": #777777,
  "success": #8FC485,
  "danger": #D87373,
  "warning": #ED9B00, 
  "appointment_scheduled": #FFB238,
  "qualified_to_buy": #FF6B38,
  "presentation_scheduled": #C75146,
  "contract_sent": #AD2E24,
  "closed_won": #81171B,
  "closed_lost": #540804,
  "freezed": #87A6FA,
  "products": #AD2E24,
  "services": #8FC485
);

$OpenSans: 'Open Sans', sans-serif;

$menu-size: 130px;
